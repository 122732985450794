import React from "react";
import "../styles/notifications.css";
import Notification from "./Notification";
import useRandom from "../../common_hooks/random.hook";

const Notifications = ({
  notifications,
  showNotifications,
  setNotifications,
  setNoticeDisplay,
  setNoticeText,
  setUserFriends,
  noticeRef,
}) => {
  //Блок с уведомлениями
  const { randomKey } = useRandom();
  return (
    <div className="notifications block">
      <p onClick={showNotifications} className="notifications-title">
        Уведомления |{" "}
        <span onClick={showNotifications} className="close-notifications">
          Закрыть
        </span>
      </p>
      {notifications[0] !== undefined ? (
        notifications.map((el) => (
          <Notification
            key={randomKey()}
            id={el._id}
            title={el.title}
            type={el.type}
            from={el.from}
            to={el.to}
            postType={el.postType}
            postID={el.postID}
            notifications={notifications}
            setNotifications={setNotifications}
            setNoticeDisplay={setNoticeDisplay}
            setNoticeText={setNoticeText}
            setUserFriends={setUserFriends}
            noticeRef={noticeRef}
          />
        ))
      ) : (
        <p style={{ color: "white" }}>У вас нет уведомлений</p>
      )}
    </div>
  );
};

export default Notifications;
