import React from "react";
import "../../styles/RoomNoMessages.css";

export default function RoomNoMessages() {
  return (
    <div className="room-no-messages">
      <span className="room-no-messages-text">Здесь пока нет сообщений...</span>
    </div>
  );
}
