export const smiles = [
    {code: '😀'},
    {code: '😃'},
    {code: '😄'},
    {code: '😁'},
    {code: '😆'},
    {code: '😅'},
    {code: '🤣'},
    {code: '😂'},
    {code: '🙂'},
    {code: '🙃'},
    {code: '😉'},
    {code: '😊'},
    {code: '😇'},
    {code: '🥰'},
    {code: '😍'},
    {code: '🤩'},
    {code: '😘'},
    {code: '😗'},
    {code: '😚'},
    {code: '😙'},
    {code: '😋'},
    {code: '😛'},
    {code: '😜'},
    {code: '🤪'},
    {code: '😝'},
    {code: '🤑'},
    {code: '🤗'},
    {code: '🤭'},
    {code: '🤫'},
    {code: '🤔'},
    {code: '🤐'},
    {code: '🤨'},
    {code: '😐'},
    {code: '😑'},
    {code: '😶'},
    {code: '😏'},
    {code: '😒'},
    {code: '🙄'},
    {code: '😬'},
    {code: '🤥'},
    {code: '😌'},
    {code: '😔'},
    {code: '😪'},
    {code: '🤤'},
    {code: '😴'},
    {code: '😷'},
    {code: '🤒'},
    {code: '🤕'},
    {code: '🤢'},
    {code: '🤮'},
    {code: '🤧'},
    {code: '🥵'},
    {code: '🥶'},
    {code: '🥴'},
    {code: '😵'},
    {code: '🤯'},
    {code: '🤠'},
    {code: '🥳'},
    {code: '😎'},
    {code: '🤓'},
    {code: '🧐'},
    {code: '😕'},
    {code: '😟'},
    {code: '🙁'},
    {code: '😮'},
    {code: '😯'},
    {code: '😲'},
    {code: '😳'},
    {code: '🥺'},
    {code: '😦'},
    {code: '😧'},
    {code: '😨'},
    {code: '😰'},
    {code: '😥'},
    {code: '😢'},
    {code: '😭'},
    {code: '😱'},
    {code: '😖'},
    {code: '😣'},
    {code: '😞'},
    {code: '😓'},
    {code: '😩'},
    {code: '😫'},
    {code: '😤'},
    {code: '😡'},
    {code: '😠'},
    {code: '🤬'},
    {code: '😈'},
    {code: '👿'},
    {code: '💀'},
    {code: '💋'},
    {code: '💌'},
    {code: '💘'},
    {code: '💝'},
    {code: '💖'},
    {code: '💗'},
    {code: '💓'},
    {code: '💞'},
    {code: '💕'},
    {code: '💟'},
    {code: '💔'},
    {code: '🧡'},
    {code: '💛'},
    {code: '💚'},
    {code: '💙'},
    {code: '💜'},
    {code: '🖤'},
    {code: '💥'},
]